import { Model } from '@vuex-orm/core'
import AtmCase from '@/models/AtmCase'
import Document from '@/models/Document'
export default class Portion extends Model {
    static entity = "portions"
    static primaryKey = "uuid"

    static fields() {
        return {
            uuid: this.uid(),
            name: this.string(''),
            individualHousingLength: this.number(0),
            individualBuildingLength: this.number(0),
            collectiveHousingLength: this.number(0),
            collectiveBuildingLength: this.number(0),
            isTerracedHouses: this.string(null),
            commonInternalCirculations: this.string(null),
            certification: this.string(''),
            conceptionOutsideNoises: this.string(''),
            conceptionInsideNoises: this.string(''),
            executionOutsideNoises: this.string(''),
            executionInsideNoises: this.string(''),
            measurementDocumentUuid: this.string(null),
            document: this.hasOne(Document, 'uuid', 'measurementDocumentUuid'),
            maximumInsulationTarget: this.number(0),
            minimumNumberMandatoryMeasures: this.number(0),
            measurementTarget: this.string(''),
            measurementTargetAdditionnalInformations: this.string(''),
            measurementReference: this.string(''),
            measurementReferenceAdditionnalInformations: this.string(''),
            areMeasurementsProducedByOtherOrganization: this.string(null),
            measurementsPerformedDetails: this.string(''),

            caseInfo: this.string(''),
            atmCase : this.hasOne(AtmCase, 'atmUuid', 'caseInfo')
        }
    }

    get individualHousing() {
        return this.individualHousingLength
    }

    get collectiveHousing() {
        return this.collectiveHousingLength
    }

    get dataImported() {    
        return Boolean(this.measurementDocumentUuid)
    }
}