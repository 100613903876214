import { resolveUser, updateUser } from "@/api/atm-back";
import { searchUser } from "@/api/user-management-back";
import User from "@/models/User";

const state = {
  currentUser: null
};

const getters = {
  getCurrentUser: state => {
    return state.currentUser;
  },
  getAllUsers: () => {
    return User.all();
  },
};

const actions = {
  async initializeCurrentUser({ commit }) {
    try {
      const userData = await resolveUser();
      await User.insert({
        data: { ...userData, isCurrentUser: true }
      });

      const res = await commit("SET_CURRENT_USER", new User(userData));
      return res;
    } catch (err) {
      console.log(err);
    }
  },
  async updateCurrentUser({ rootGetters, commit }, userData) {
    try {
      const userDataResponse = await updateUser(userData);
      await User.insertOrUpdate({
        data: [userDataResponse]
      });
      const res = await commit("SET_CURRENT_USER", new User({ ...rootGetters.getCurrentUser, ...userDataResponse }));
      return res;
    } catch (err) {
      console.log(err);
    }
  },
  async searchUser(_, text) {
    try {
      const userDataResponse = await searchUser(text);
      return userDataResponse;
    } catch (err) {
      console.log(err);
    }
  }
};

const mutations = {
  SET_CURRENT_USER: (state, user) => {
    state.currentUser = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
